import 'ol/ol.css';
import {Map, View, proj} from 'ol';
import TileLayer from 'ol/layer/Tile';
import proj4 from 'proj4';
import {register} from 'ol/proj/proj4';
import XYZ from 'ol/source/XYZ';
import TileGrid from 'ol/tilegrid/TileGrid';
import * as ko from 'knockout';
import { PostgrestClient } from '@supabase/postgrest-js';
import Search from './app/Search';
import RatioGradientGenerator from './app/RatioGradientGenerator';
import {Cluster, Vector} from 'ol/source';
import {GeoJSON} from 'ol/format';
import {bbox} from 'ol/loadingstrategy';
import VectorLayer from 'ol/layer/Vector';
import {Icon, Style, RegularShape, Stroke, Fill, Text} from 'ol/style';
import {boundingExtent} from 'ol/extent';

//we're declaring these explicitly so parcel can bundle them. Dynamic fails.
const pinIconUrls = {
  'allowed':  new URL(`./icons/map-pin-allowed.svg`, import.meta.url),
  'refused': new URL(`./icons/map-pin-refused.svg`, import.meta.url),
  'split': new URL(`./icons/map-pin-split.svg`, import.meta.url),
  'unknown':  new URL(`./icons/map-pin-unknown.svg`, import.meta.url)
};

const getApiUrl = function () {
    if (window.location.host.indexOf('localhost') > -1) {
        return 'https://appeals.planningparrot.uk/api';
        return 'http://localhost:8830/api';
    } else {
        return 'https://appeals.planningparrot.uk/api';
    }
};

const PP_API_URL = getApiUrl();
const postgrest = new PostgrestClient(PP_API_URL);

proj4.defs(
  'EPSG:27700',
  '+proj=tmerc ' +
  '+lat_0=49 +lon_0=-2 ' +
  '+k=0.9996012717 +x_0=400000 +y_0=-100000 ' +
  '+ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 ' +
  '+units=m ' +
  '+no_defs'
);

register(proj4);

const ratioGradientGenerator = new RatioGradientGenerator('green', 'red');

const makeMapPinStyle = function(normalizedOutcome) {
    const pinIconUrl = pinIconUrls[normalizedOutcome];
    return new Style({
      image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: pinIconUrl.pathname,
          scale: [2,2]
      }),
  });
};

const pinStyle = {
  'allowed': makeMapPinStyle('allowed'),
  'refused': makeMapPinStyle('refused'),
  'split':   makeMapPinStyle('split'),
  'unknown': makeMapPinStyle('unknown')
};

const getNormalizedOutcome = function (feature) {
  const planningInspectorateData = feature.get('upstream') ?? {};
  let upstreamOutcome = planningInspectorateData['outcome'] || '';
  upstreamOutcome = upstreamOutcome.toLowerCase();
  if (upstreamOutcome.indexOf('allowed') > -1) {
    return 'allowed';
  } else if (
    upstreamOutcome.indexOf('refused') > -1 ||
    upstreamOutcome.indexOf('dismissed') > -1 ||
    upstreamOutcome.indexOf('upheld') > -1
  ) {
    return 'refused';
  } else if (upstreamOutcome.indexOf('split') > -1) {
    return 'split';
  } else {
    return 'unknown';
  }

}

const isAllowed = function (feature) {
  return getNormalizedOutcome(feature) == 'allowed';
}

var vectorSource = new Vector({
    format: new GeoJSON({dataProjection: 'EPSG:27700'}),
    loader: function(extent, resolution, projection, success, failure) {
       var proj = projection.getCode();
       var url = PP_API_URL + "/appeals?documents.filetext=phfts(english). {% prompt 'Query String', '', 'conservation', 'query string', false, true %}&select=*,properties.documents:documents(filename)";
       var xhr = new XMLHttpRequest();
       xhr.open('GET', url);
       var onError = function() {
         vectorSource.removeLoadedExtent(extent);
         failure();
       }
       xhr.onerror = onError;
       xhr.onload = function() {
         if (xhr.status == 200) {
           var geojsonHeader = '{"type": "FeatureCollection", "features":';
           var geojsonFooter = '}';
           var features = vectorSource.getFormat().readFeatures(geojsonHeader + xhr.responseText + geojsonFooter);
           vectorSource.addFeatures(features);
           success(features);
         } else {
           onError();
         }
       }
       xhr.send();
     },
     strategy: bbox
   });

//round to the nearest 10
const roundToNearestTen = function (num) {
    return Math.round(num / 10) * 10;
}

const calcBucketedSize = function (size) {
    if (size > 1e6) {
        return '1M+';
    } else if (size > 1e3) {
        return '1K+';
    } else if (size > 99) {
        return '99+';
    } else if (size < 10) {
        return size.toString();
    } else {
        roundToNearestTen(size).toString() + '+';
    }
}

//round to the nearest 0.1
const roundToNearestDecimalPoint = function (num) {
    return Math.round(num * 10) / 10;
};

const clusterSource = new Cluster({
    distance: 10,
    minDistance: 0,
    source: vectorSource,
});

const styleCache = {
    '1M+': {},
    '1K+': {},
    '99+': {},
    '90+': {},
    '80+': {},
    '70+': {},
    '60+': {},
    '50+': {},
    '40+': {},
    '30+': {},
    '20+': {},
    '10+': {},
    '9': {},
    '8': {},
    '7': {},
    '6': {},
    '5': {},
    '4': {},
    '3': {},
    '2': {}
};
const clusters = new VectorLayer({
    source: clusterSource,
    style: function (cluster) {
      const size = cluster.get('features').length;
      const isAllowedSize = cluster.get('features').filter(isAllowed).length;

      if (size === 1) {
        const feature = cluster.get('features')[0];
        let normalizedOutcome = getNormalizedOutcome(feature);
        return pinStyle[normalizedOutcome];
      } else {
        let bucketedSize = calcBucketedSize(size);
        let approvalRate = roundToNearestDecimalPoint(isAllowedSize / size);
        let style = styleCache[bucketedSize][approvalRate];
        if (!style) {
            style = new Style({
            image: new RegularShape({
              radius: 512,
              scale: [0.05, 0.02],
              points: 4,
              angle: Math.PI / 4,
              fill: new Fill({
                color: ratioGradientGenerator.getGradient(approvalRate)
              }),
              stroke: new Stroke({
                  color: '#fff',
                  width: 1,
              }),
            }),
            text: new Text({
                font:  '14px sans-serif',
                text: bucketedSize,
                //textBaseline: 'bottom',
                fill: new Fill({
                    color: 'black',
                }),
                stroke: new Stroke({
                    color: '#fff',
                    width: 3,
                })
            }),
            });
            style = styleCache[bucketedSize][approvalRate] = style;
        }
        return style;
        }
    },
  });

const basemap = 
new TileLayer({
  source: new XYZ({
    attributions: '© Ordnance Survey ' + (new Date()).getFullYear(),
    tileUrlFunction: function (zxy) {
      //var y = -zxy[2] - 1;
      var y= zxy[2];
      return (
          'https://api.os.uk/maps/raster/v1/zxy/Leisure_27700/'
          + zxy[0] + '/' + zxy[1] + '/' + y + '.png' +
          '?key=UeVGP6BplJAljH4jn48hbzHu1ag95Mwj'
      );
    },
    projection: 'EPSG:27700',
    wrapX: false,
    tileGrid: new TileGrid({
      resolutions:  [
        896.0,
        448.0,
        224.0,
        112.0,
        56.0,
        28.0,
        14.0,
        7.0,
        3.5,
        1.75,
        0.875,
        0.4375,
        0.21875,
        0.109375
      ],
      origin: [ -238375.0, 1376256.0 ]
    })
  }),
});//TILELAYER

/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}

window.map = new Map({
  target: 'map',
  layers: [
     basemap,
     clusters
    // , new VectorLayer({
    //     source: vectorSource,
    //     projection: 'EPSG:27700',
    //     style: styleFunction
    // })
  ],
  view: new View({
    center: [200000,250000],
    resolutions:   [ 896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75, 0.875, 0.4375, 0.21875, 0.109375 ],
    resolution: 896.0,
    projection: 'EPSG:27700'
})
});

const getCurrentISOWeek = function () {
    const weekNum = getWeekNumber(new Date());
    const year = (new Date()).getFullYear();
    return `${weekNum[0]}-W${weekNum[1]}`;
}

window.vm = {
    search: new Search(postgrest),
    selectedFeature: ko.observable(),
    week: ko.observable(getCurrentISOWeek()),
}

const handleClickedFeatures = function (clickedFeatures) {
    console.log(clickedFeatures);
    if (!clickedFeatures.length) {
        vm.selectedFeature(null);
    } else {
      // Get clustered Coordinates
      const features = clickedFeatures[0].get('features');
      if (features.length > 1) {
        const extent = boundingExtent(
          features.map((r) => r.getGeometry().getCoordinates())
        );
        map.getView().fit(extent, {duration: 1000, padding: [50, 50, 50, 50]});
      } else {
        vm.selectedFeature(features[0]);
      }
    }
  }

map.on('click', (e) => {
    clusters.getFeatures(e.pixel).then(handleClickedFeatures);
});

window.map.getView().setConstrainResolution(true);

ko.applyBindings(vm);