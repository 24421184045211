import * as ko from 'knockout';
import TimedMessage from './TimedMessage';
import SearchResult from './SearchResult';

export default class Search {
    postgrest;
    hasResults;
    term = ko.observable();
    isSearching = ko.observable(false);
    results = ko.observableArray([]);
    message = {
        empty: new TimedMessage(),
        error: new TimedMessage()
    };
    
    constructor (postgrestClient) {
        this.postgrest = postgrestClient;
        this.hasResults = ko.pureComputed(this.computeHasResults, this);
        this.isSearching.subscribe(this.brokerMessagesToUser, this);
    }
};

Search.prototype.computeHasResults = function () {
    return this.results().length > 0;
};

Search.prototype.brokerMessagesToUser = function (isSearching) {
    if (isSearching || this.hasResults()) {
        this.message.empty.clear();
        this.message.error.clear();
    } else {
        this.message.empty.activate();
    }
};

Search.prototype.send = function () {
    const ftsConfig = {
        //type: 'websearch', //FIXME: use after upgrading postgres
        type: 'phrase',
        config: 'english'
    };
    
    this.isSearching(true);

    this.postgrest
        .from('appeal_documents')
        .select('filename,filetext')
        .textSearch('filetext', this.term(), ftsConfig)
        .then(this.handleResults.bind(this))
        .catch(this.handleResultsFailure.bind(this))
        .finally(this.finishSearching.bind(this));
};

Search.prototype.handleResults = function (response) {
    let documentToSearchResult;

    if (response.error !== null) {
        throw response;
    } else {
        documentToSearchResult = function (document) {
            return new SearchResult(
                document.filename,
                document.filetext.substring(0, 200)
            );
        };
        
        this.results(response.body.map(documentToSearchResult));
    }
};

Search.prototype.handleResultsFailure = function (response) {
    console.log(response);
    this.message.error.activate();
};

Search.prototype.finishSearching = function () {
    this.isSearching(false);
};