export default class RatioGradientGenerator { 
    colourA;
    colourB;
    canvas;
    context;
    gradientCache;    


    constructor (colourA, colourB) {    
        this.colourA = colourA;
        this.colourB = colourB;
        this.canvas = document.createElement('canvas');
        this.context = this.canvas.getContext('2d');
        this.gradientCache = {};
    }
};

RatioGradientGenerator.prototype.getGradient = function (ratio) {
    let gradient = this.gradientCache[ratio];

    if (gradient) {
        return gradient;
    } else {
        gradient = this.context.createLinearGradient(-256, 0, 256, 0);
        gradient.addColorStop(0, this.colourA);
        gradient.addColorStop(ratio, this.colourA);
        gradient.addColorStop(ratio, this.colourB);
        gradient.addColorStop(1, this.colourB);
        this.gradientCache[ratio] = gradient;
        return gradient;
    }
};