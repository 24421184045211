export default class SearchResult {
    title;
    snippit;
    constructor (title, snippit) {
        this.title = title;
        this.snippit = snippit;
    }
};


var name = "Peter";

