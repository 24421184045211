import * as ko from 'knockout';

export default class TimedMessage {
    timer = ko.observable();
    isActive = ko.pureComputed(() => {
        return this.timer();
    });

    contructor (message) {
        this.message = message || '';
    }
};

TimedMessage.prototype.activate = function () {
    let timerId = setTimeout(() => this.clear(), 5000);
    this.timer(timerId);
};

TimedMessage.prototype.clear = function () {
    if (this.timer()) {
        clearTimeout(this.timer());
    }
    this.timer(null);
};